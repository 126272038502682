<template>
  <a-modal
    title="新建"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <a-form-item label="编号" >
              <a-input placeholder="编号" v-decorator="['code', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" />
            </a-form-item>
            <a-form-item label="名称">
              <a-input placeholder="名称" v-decorator="['name', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]"/>
            </a-form-item>
            <a-form-item label="所属企业" >
              <a-select
                showSearch
                v-decorator="['corporation_id', {rules: [{required: false, message: '必填项，请填写信息'}]}]"
                placeholder="请输入企业名称"
                style="width: 100%"
                :filter-option="false"
                :not-found-content="fetching ? undefined : null"
                @search="this.corporationSearch"
                @change="handleDataChange"
                allow-clear
              >
                <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                <a-select-option v-for="d in corporationList" :key="d.id" :value="d.id">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import { corporation_list } from '@/api/corporation'
import debounce from 'lodash/debounce'

// 表单字段
const fields = ['id', 'name', 'wms_warehouse_name', 'code', 'wms_supervised_warehouse_id']
export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    this.corporationSearch = debounce(this.fetchData, 800)
    return {
      fetching: false,
      corporationList: [],
      form: this.$form.createForm(this)
    }
  },
  created () {
    console.log('custom modal created', this.model)
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
    this.fetchData(undefined)
  },
  methods: {
    fetchData (value) {
      console.log('fetching user', value)
      this.corporationList = []
      this.fetching = true
      corporation_list({ name: value }).then(({ data }) => {
        const result = data.entries || []
        this.corporationList = result
        this.fetching = false
      })
    },
    handleDataChange (value) {
      console.log(value)
      if (value === undefined) {
        this.fetchData(value)
      }
    }
  }
}
</script>
